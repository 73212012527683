import React, { useState } from 'react';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MuiTelInput } from 'mui-tel-input';

interface TextInputProps extends OutlinedTextFieldProps {
  isPassword?: boolean;
  isPhoneNumber?: boolean;
}

const MaterialTextField: React.FC<TextInputProps> = ({
  isPassword = false,
  isPhoneNumber = false,
  ...textFieldProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, screenPhoneNumber] = useState('');

  const handlePhoneNumberChange = (value: any) => {
    screenPhoneNumber(value);
  };

  const commonInputProps = {
    fullWidth: true,
    InputLabelProps: { style: { color: 'white' } },
    sx: { fieldset: { borderColor: 'white' } },
  };

  return (
    <>
      {isPhoneNumber ? (
        <MuiTelInput
          {...textFieldProps}
          {...commonInputProps}
          defaultCountry={'US'}
          inputProps={{ maxLength: 15 }}
          margin="normal"
          color="info"
          onChange={handlePhoneNumberChange}
          value={phoneNumber}
        />
      ) : (
        <TextField
          {...textFieldProps}
          {...commonInputProps}
          type={isPassword && !showPassword ? 'password' : 'text'}
          margin="normal"
          color="info"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  color="info"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            style: { color: 'white' },
          }}
        />
      )}
    </>
  );
};

export default MaterialTextField;
