const themeColors = {
  // light: will be calculated from palette.primary.main,
  // dark: will be calculated from palette.primary.main,
  // contrastText: will be calculated to contrast with palette.primary.main
  primary: { main: '#F0544F' },
  secondary: { main: '#191920' },
  info: { main: '#ffffff' },
  text: { primary: '#ffffff' },
};

// Define the font object
const themeTypography = {
  fontFamily: 'General Sans, sans-serif',
};

// Export the color and font objects
export { themeColors, themeTypography };