import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { themeColors, themeTypography } from './theme';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';

const theme = createTheme({
  palette: { ...themeColors },
  typography: { ...themeTypography },
});

function App() {
  // TODO: wrap home route element in <RequireAuth>
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
