import React from 'react';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import MaterialTextField from '../components/material/textField';

import logo from '../assets/images/logos/logo-with-text_light.png';
import './Login.scss';

function Login() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      number: data.get('phoneNumber'),
      password: data.get('password'),
    });
  };

  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mt: 1, mb: 3 }}>
          <img className="logo" src={logo} alt="logo-with-text" />
        </Box>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <MaterialTextField
            isPhoneNumber
            label="Phone Number"
            name="phoneNumber"
            id="phone-number"
            variant={'outlined'}
          />
          <MaterialTextField
            label="Password"
            name="password"
            id="password"
            variant={'outlined'}
            isPassword
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 5, mb: 2 }}
          >
            Sign In
          </Button>
          {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.primary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://dunbar-app.com/">
        Dunbar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Login;
